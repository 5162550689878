var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"f2g-icon flex-col-center"},[(_vm.tooltip)?_c('v-tooltip',{attrs:{"bottom":_vm.isSelectedPosition('bottom'),"left":_vm.isSelectedPosition('left'),"right":_vm.isSelectedPosition('right'),"top":_vm.isSelectedPosition('top'),"max-width":"800px"},on:{"mouseover":function($event){_vm.withHover ? (_vm.hover = true) : ''},"mouseleave":function($event){_vm.withHover ? (_vm.hover = false) : ''}},scopedSlots:_vm._u([{key:"activator",fn:function({ on }){return [_c('svg',_vm._g({staticClass:"tooltip-border",class:{ 'border-radius': _vm.borderRadius },style:({ width: _vm.width + 'px', height: _vm.height + 'px' }),on:{"click":function($event){return _vm.$emit('click')}}},on),[_c('use',_vm._b({staticClass:"orion-icon",style:({
            stroke: _vm.hover && _vm.stroke ? _vm.changeColor : _vm.stroke,
            'stroke-width': _vm.strokeWidth,
            fill: _vm.hover && _vm.fillLined ? _vm.changeColor : _vm.fillLined,
          })},'use',{ 'xlink:href': _vm.spriteUrl + '#' + _vm.value },false))])]}}],null,false,2350477501)},[_c('span',{domProps:{"innerHTML":_vm._s(_vm.tooltip)}})]):_c('div',{style:({
      width: _vm.width + 'px',
      height: _vm.height + 'px',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
    }),on:{"click":function($event){return _vm.$emit('click', $event)},"mouseover":function($event){_vm.withHover ? (_vm.hover = true) : ''},"mouseleave":function($event){_vm.withHover ? (_vm.hover = false) : ''}}},[_c('svg',{class:{ 'border-radius': _vm.borderRadius },style:({ width: _vm.width + 'px', height: _vm.height + 'px' })},[(!_vm.solid)?_c('use',_vm._b({staticClass:"orion-icon",style:({
          stroke: _vm.hover && _vm.stroke ? _vm.changeColor : _vm.stroke ? _vm.stroke : '',
          'stroke-width': _vm.strokeWidth,
          fill: _vm.hover && _vm.fillLined ? _vm.changeColor : _vm.fillLined,
        })},'use',{ 'xlink:href': _vm.spriteUrl + '#' + _vm.value },false)):_c('use',_vm._b({staticClass:"orion-icon solid",style:({ fill: _vm.fillLined })},'use',{ 'xlink:href': _vm.spriteUrl + '#' + _vm.value },false))])])],1)
}
var staticRenderFns = []

export { render, staticRenderFns }