<template>
  <div class="f2g-icon flex-col-center">
    <v-tooltip
      v-if="tooltip"
      :bottom="isSelectedPosition('bottom')"
      :left="isSelectedPosition('left')"
      :right="isSelectedPosition('right')"
      :top="isSelectedPosition('top')"
      max-width="800px"
      @mouseover="withHover ? (hover = true) : ''"
      @mouseleave="withHover ? (hover = false) : ''"
    >
      <template v-slot:activator="{ on }">
        <svg
          v-on="on"
          class="tooltip-border"
          :class="{ 'border-radius': borderRadius }"
          :style="{ width: width + 'px', height: height + 'px' }"
          @click="$emit('click')"
        >
          <use
            class="orion-icon"
            :style="{
              stroke: hover && stroke ? changeColor : stroke,
              'stroke-width': strokeWidth,
              fill: hover && fillLined ? changeColor : fillLined,
            }"
            v-bind="{ 'xlink:href': spriteUrl + '#' + value }"
          />
        </svg>
      </template>
      <span v-html="tooltip"></span>
    </v-tooltip>

    <!-- No tooltip -->
    <div
      v-else
      @click="$emit('click', $event)"
      @mouseover="withHover ? (hover = true) : ''"
      @mouseleave="withHover ? (hover = false) : ''"
      :style="{
        width: width + 'px',
        height: height + 'px',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
      }"
    >
      <svg
        :class="{ 'border-radius': borderRadius }"
        :style="{ width: width + 'px', height: height + 'px' }"
      >
        <use
          v-if="!solid"
          class="orion-icon"
          :style="{
            stroke: hover && stroke ? changeColor : stroke ? stroke : '',
            'stroke-width': strokeWidth,
            fill: hover && fillLined ? changeColor : fillLined,
          }"
          v-bind="{ 'xlink:href': spriteUrl + '#' + value }"
        />

        <!-- Solid Icon -->
        <use
          v-else
          class="orion-icon solid"
          :style="{ fill: fillLined }"
          v-bind="{ 'xlink:href': spriteUrl + '#' + value }"
        />
      </svg>
    </div>
  </div>
</template>

<script>
export default {
  name: "F2gIcon",
  props: {
    value: String,
    width: {
      type: Number,
      default: 24,
    },
    height: {
      type: Number,
      default: 24,
    },
    strokeWidth: {
      type: Number,
      default: 2,
    },
    stroke: {
      type: String,
      default: "#202020",
    },
    fill: {
      type: String,
      default: "none",
    },
    file: {
      type: String,
      default: "icons_lined.svg",
    },
    tooltip: {
      type: String,
      default: "",
    },
    solid: {
      type: Boolean,
      default: false,
    },
    borderRadius: {
      type: Boolean,
      default: false,
      required: false,
    },
    tooltipPosition: {
      type: String,
      default: "bottom",
    },
    changeColor: {
      type: String,
      required: false,
    },
    withHover: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      spriteUrl: require("@/flit2go-web-core/assets/" + this.file),
      hover: false,
    };
  },
  computed: {
    fillLined() {
      return this.value === "MOP" ? "rgb(32, 32, 32)" : this.fill;
    },
    fillSolid() {
      return this.fill === "none" ? "" : this.fill;
    },
  },
  methods: {
    isSelectedPosition(position) {
      return position === this.tooltipPosition;
    },
  },
};
</script>
<style lang="scss" scoped>
@import "@/flit2go-web-core/shared-styles/animations/writing.scss";
@include writing();
.f2g-icon {
  .tooltip-border {
    border: none;
    background-color: transparent;
    resize: none;
    outline: none;
  }
  use.orion-icon {
    fill-opacity: 0;
    stroke-dasharray: 1000;
    stroke-dashoffset: 1000;
    stroke-width: 1;

    $step-time: 0;
    @for $i from 1 through 5 {
      &:nth-child(#{$i}) {
        -webkit-animation: writing 1000ms ease-in forwards #{$step-time}s;
        animation: writing 1000ms ease-in forwards #{$step-time}s;
        $step-time: $step-time + 0.4;
      }
    }
    svg {
      height: 34px;
      width: 34px;
    }
  }

  .border-radius {
    border-radius: 80%;
  }
}
</style>
